import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { connectSocialMedia } from '../../utils/api';

const YouTubeConnect = () => {
  const { user, refreshToken } = useAuth();
  const handleConnect = async () => {
    try {
      await refreshToken();
      const authUrl = await connectSocialMedia('youtube', user.token);
      if (authUrl && typeof authUrl === 'string') {
        // Store user ID in session before redirecting
        fetch('/youtube/store-user-id', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': document.cookie.split('XSRF-TOKEN=')[1]
          },
          body: JSON.stringify({ userId: user.userId })
        }).then(() => {
          window.location.href = authUrl;
        });
      } else {
        console.error('Invalid auth URL received:', authUrl);
      }
    } catch (error) {
      console.error('Failed to initiate YouTube connection:', error.response ? error.response.data : error.message);
    }
  };  return (
    <button onClick={handleConnect}>Connect YouTube</button>
  );
};

export default YouTubeConnect;
