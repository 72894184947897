import axios from 'axios';
import { debounce } from 'lodash';

axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = document.cookie.split('XSRF-TOKEN=')[1];
  if (token) {
    config.headers['X-CSRF-Token'] = token.split(';')[0];
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

const API_URL = 'https://luenstaeden.de/api';

const getAuthHeader = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user && user.token ? { Authorization: `Bearer ${user.token}` } : {};
};

export const getInfluencers = async () => {
  try {
    const response = await axios.get(`${API_URL}/agency/influencers`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching influencers:', error);
    throw error;
  }
};

export const getCampaigns = async () => {
  try {
    const response = await axios.get(`${API_URL}/agency/campaigns`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const inviteInfluencer = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/invit/invitations/send`, 
      { recipientEmail: email },
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error('Error inviting influencer:', error);
    throw error;
  }
};

export const getYouTubeAnalytics = async (userToken, accessToken, refreshToken) => {
  try {
    const response = await axios.get(`${API_URL}/yt/youtube-insights`, {
      headers: { 
        Authorization: `Bearer ${userToken}`,
        'YouTube-Access-Token': accessToken
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      console.error('403 Forbidden error. Full error response:', JSON.stringify(error.response.data, null, 2));
      throw new Error('Access forbidden. Please check your API credentials and permissions.');
    }
    if (error.response && error.response.status === 401) {
      console.log('Access token expired, refreshing...');
      const newTokens = await refreshYouTubeToken(refreshToken);
      await updateUserTokens(userToken, newTokens);
      console.log('Token refreshed, retrying analytics request');
      return getYouTubeAnalytics(userToken, newTokens.access_token, refreshToken);
    }
    console.error('Error fetching YouTube analytics:', error);
    throw error;
  }
};export const getVideoTitles = async (userToken, videoIds) => {
  try {
    const response = await axios.post(`${API_URL}/yt/youtube-video-titles`, { videoIds }, {
      headers: { Authorization: `Bearer ${userToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching video titles:', error);
    throw error;
  }
};

async function refreshYouTubeToken(refreshToken) {
  const response = await axios.post(`${API_URL}/yt/refresh-youtube-token`, { refreshToken }, {
    headers: getAuthHeader()
  });
  return response.data;
}

async function updateUserTokens(userToken, newTokens) {
  await axios.post(`${API_URL}/yt/update-youtube-tokens`, newTokens, {
    headers: { Authorization: `Bearer ${userToken}` }
  });
}

export const getInfluencerDetails = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/agency/influencers/${id}`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching influencer details:', error);
    throw error;
  }
};

export const getCampaignDetails = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/agency/campaigns/${id}`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching campaign details:', error);
    throw error;
  }
};

export const register = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/users/register`, userData, {
      headers: {
        'X-CSRF-Token': document.cookie.split('XSRF-TOKEN=')[1].split(';')[0]
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during registration:', error.response?.data || error.message);
    throw error;
  }
};

export const login = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/users/login`, credentials, {
      headers: {
        'X-CSRF-Token': document.cookie.split('XSRF-TOKEN=')[1].split(';')[0]
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};

export const getConnectedAccounts = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/connected-accounts`, {
      headers: { 
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': document.cookie.split('XSRF-TOKEN=')[1].split(';')[0]
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching connected accounts:', error);
    throw error;
  }
};  

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const response = await axios.post(`${API_URL}/refresh-token`, { refreshToken });
  return response.data;
};

export const connectSocialMedia = async (platform, token) => {
  try {
    const response = await axios.get(`${API_URL}/connect-social-media/${platform}`, {
      headers: { 
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': document.cookie.split('XSRF-TOKEN=')[1].split(';')[0]
      },
      withCredentials: true
    });
    return response.data.authUrl; // Return only the authUrl, not the entire response
  } catch (error) {
    console.error(`Error initiating ${platform} connection:`, error);
    throw error;
  }
};

export const disconnectSocialMedia = async (platform) => {
  try {
    await axios.post(`${API_URL}/disconnect/${platform}`, null, {
      headers: getAuthHeader()
    });
  } catch (error) {
    console.error(`Error disconnecting ${platform}:`, error);
    throw error;
  }
};

export const getAgencyData = async () => {
  try {
    const response = await axios.get(`${API_URL}/dashboard/agency-data`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching agency data:', error);
    throw error;
  }
};

export const getManagedInfluencers = async () => {
  try {
    const response = await axios.get(`${API_URL}/agency/managed-influencers`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching managed influencers:', error);
    throw error;
  }
};

export const changePassword = async (token, currentPassword, newPassword) => {
  try {
    const response = await axios.post(`${API_URL}/users/change-password`, 
      { currentPassword, newPassword },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};

export const getInstagramInsights = async (userToken, accessToken, refreshToken) => {
  try {
    const response = await axios.get(`${API_URL}/ig/instagram-insights`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Instagram-Access-Token': accessToken,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log('Access token expired, refreshing...');
      const newTokens = await refreshInstagramToken(refreshToken);
      await updateInstagramTokens(userToken, newTokens);
      console.log('Token refreshed, retrying insights request');
      return getInstagramInsights(userToken, newTokens.access_token, refreshToken);
    }
    console.error('Error fetching Instagram insights:', error);
    throw error;
  }
};

async function refreshInstagramToken(refreshToken) {
  const response = await axios.post(`${API_URL}/ig/refresh-instagram-token`, { refreshToken }, {
    headers: getAuthHeader(),
  });
  return response.data;
}

async function updateInstagramTokens(userToken, newTokens) {
  await axios.post(`${API_URL}/ig/update-instagram-tokens`, newTokens, {
    headers: { Authorization: `Bearer ${userToken}` }
  });
}

export const getTwitchAnalytics = async (token, accessToken, refreshToken) => {
  try {
    const response = await axios.get(`${API_URL}/twt/twitch-analytics`, {
      headers: { 
        Authorization: `Bearer ${token}`,
        'Twitch-Access-Token': accessToken
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log('Access token expired, refreshing...');
      const newTokens = await refreshTwitchToken(refreshToken);
      await updateTwitchTokens(token, newTokens);
      console.log('Token refreshed, retrying analytics request');
      return getTwitchAnalytics(token, newTokens.access_token, refreshToken);
    }
    console.error('Error fetching Twitch analytics:', error);
    throw error;
  }
};

async function refreshTwitchToken(refreshToken) {
  const response = await axios.post(`${API_URL}/twt/refresh-twitch-token`, { refreshToken }, {
    headers: getAuthHeader(),
  });
  return response.data;
}

async function updateTwitchTokens(userToken, newTokens) {
  await axios.post(`${API_URL}/twt/update-twitch-tokens`, newTokens, {
    headers: { Authorization: `Bearer ${userToken}` }
  });
}

export const getTikTokAnalytics = async (token, accessToken, refreshToken) => {
  try {
    const response = await axios.get(`${API_URL}/tt/tiktok-analytics`, {
      headers: { 
        Authorization: `Bearer ${token}`,
        'TikTok-Access-Token': accessToken
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log('Access token expired, refreshing...');
      const newTokens = await refreshTikTokToken(refreshToken);
      await updateTikTokTokens(token, newTokens);
      console.log('Token refreshed, retrying analytics request');
      return getTikTokAnalytics(token, newTokens.access_token, refreshToken);
    }
    console.error('Error fetching TikTok analytics:', error);
    throw error;
  }
};

async function refreshTikTokToken(refreshToken) {
  const response = await axios.post(`${API_URL}/tt/refresh-tiktok-token`, { refreshToken }, {
    headers: getAuthHeader(),
  });
  return response.data;
}

async function updateTikTokTokens(userToken, newTokens) {
  await axios.post(`${API_URL}/tt/update-tiktok-tokens`, newTokens, {
    headers: { Authorization: `Bearer ${userToken}` }
  });
}

export const getPendingInvitations = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/invit/invitations/pending`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching pending invitations:', error);
    throw error;
  }
};

export const acceptInvitation = async (token, invitationId) => {
  try {
    const response = await axios.post(`${API_URL}/invit/invitations/accept`, { invitationId }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error accepting invitation:', error);
    throw error;
  }
};

export const rejectInvitation = async (token, invitationId) => {
  try {
    const response = await axios.post(`${API_URL}/invit/invitations/reject`, { invitationId }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error rejecting invitation:', error);
    throw error;
  }
};

export const getConnections = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/con/connections`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching connections:', error.response?.data || error.message);
    throw error;
  }
};

export const removeConnection = async (token, connectionId) => {
  try {
    const response = await axios.delete(`${API_URL}/con/connections/${connectionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error removing connection:', error.response?.data || error.message);
    throw error;
  }
};

export const sendInvitation = async (token, recipientEmail) => {
  const response = await axios.post(`${API_URL}/invit/invitations/send`, { recipientEmail }, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
};

export const getInfluencerInsights = async (token, influencerId, platform) => {
  try {
    const response = await axios.get(`${API_URL}/con/influencer-insights/${influencerId}/${platform}`, 
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const debouncedGetConnectedAccounts = debounce(getConnectedAccounts, 300);
export const debouncedGetYouTubeAnalytics = debounce(getYouTubeAnalytics, 300);
export const debouncedGetInstagramInsights = debounce(getInstagramInsights, 300);